<template>
  <div class="p-wrap">
    <div class="p-header">
      <p-header-vue spacer border title="开票方式管理"> </p-header-vue>
      <p-select-vue class="p-select" :model="selectForm" :limit="3" :query-btn-auth="'RP-RP-CX'" :reset-btn-auth="'RP-RP-CZ'" label-width="100px" @select="query" @reset="reset">
        <el-form-item prop="name" label="组织名称" slot="1">
          <organization-select :model="record" :is-add-all="true" style="width: 220px" @handleSelectAction="generateAgentUsername"></organization-select>
        </el-form-item>

        <!--        <el-form-item prop="billingChannel" label="开票方式" slot="1">-->
        <!--          <el-select v-model="selectForm.billingChannel" placeholder="请选择">-->
        <!--            <el-option-->
        <!--                v-for="item in billingTypes"-->
        <!--                :key="item.billingChannel"-->
        <!--                :label="item.billingChannelName"-->
        <!--                :value="item.billingChannel">-->
        <!--            </el-option>-->
        <!--          </el-select>-->

        <!--        </el-form-item>-->

        <el-form-item prop="isElec" label="数电试点企业" slot="1">
          <el-select v-model="selectForm.isElec" placeholder="请选择">
            <el-option v-for="item in elecData" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="uniqueCode" label="组织编码" slot="1">
          <el-input type="text" v-model="selectForm.uniqueCode" placeholder="请输入"></el-input>
        </el-form-item>

        <el-form-item prop="taxNo" label="组织税号" slot="2">
          <el-input type="text" v-model="selectForm.taxNo" placeholder="请输入"></el-input>
        </el-form-item>
      </p-select-vue>
    </div>
    <!-- p-header end-->

    <div class="p-content" style="padding-top: 15px">
      <div class="main-top">
        <div class="top-left">
          <span>组织列表</span>
          <el-popover placement="right" width="80" trigger="click">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"> 全选 </el-checkbox>
            <div class="part"></div>
            <div slot="reference" class="rowSet"></div>
          </el-popover>
        </div>
        <div class="top-right"></div>
      </div>
      <el-table :data="records" border stripe v-loading="recordsLoading" ref="customerInfoTable" @selection-change="handleSelection" :header-cell-style="handleHeaderCellStyle" row-key="id" default-expand-all :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
        <el-table-column type="selection" width="50" fixed="left" />
        <el-table-column label="序号" type="index" width="50" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="组织名称"></el-table-column>
        <el-table-column prop="taxNo" label="组织税号"></el-table-column>
        <el-table-column prop="uniqueCode" label="组织编码"></el-table-column>
        <el-table-column prop="elec" label="数电试点企业" :formatter="elecFormatter"></el-table-column>
        <!--        <el-table-column prop="billingChannelName"  label="开票方式"></el-table-column>-->
        <el-table-column prop="operate" label="操作">
          <template slot-scope="scope">
            <div class="operate-button">
              <el-button type="text" size="medium" @click="billChange(scope.row)">修改开票方式</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

<!--      <el-pagination-->
<!--          class="p-page"-->
<!--          background-->
<!--          @size-change="handleSizeChange"-->
<!--          @current-change="handleCurrentChange"-->
<!--          :current-page.sync="paging.current"-->
<!--          :page-sizes="pageSizes"-->
<!--          :page-size="paging.size"-->
<!--          layout="total, sizes, prev, pager, next"-->
<!--          :total="paging.total"-->
<!--      ></el-pagination>-->
    </div>
    <!-- p-content end-->

    <el-dialog title="修改开票方式" :visible.sync="dialogVisible" width="45%">
      <div style="padding: 10px 30px">
        <el-row style="padding-bottom: 10px; font-size: 14px">
          <el-col :span="12">
            <div style="float: left">组织名称：{{ orgName }}</div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              数电试点企业：
              <el-select v-model="elec" placeholder="请选择" @change="elecChange">
                <el-option v-for="item in elecOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
        <el-table key="appId"  :data="tableData" stripe border :header-cell-style="{ background: 'rgb(235, 244, 255)', color: '#606266' }" style="width: 100%">
          <el-table-column prop="billingChannelName" label="开票方式" width="120"> </el-table-column>
          <el-table-column prop="appId" label="AppId">
            <template slot-scope="scope">
              <el-input type="text" :disabled="scope.row.elec" v-model="scope.row.appId" maxlength="50" />
            </template>
          </el-table-column>
          <el-table-column prop="appSecret" label="AppSecret">
            <template slot-scope="scope">
              <el-input type="text" :disabled="scope.row.elec" v-model="scope.row.appSecret" maxlength="50" />
            </template>
          </el-table-column>
          <el-table-column
              prop="enabled"
              label="启用"
              width="140">
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.enabled"
                  active-text="是"
                  inactive-text="否"
                  :disabled="scope.row.elec"
              >
              </el-switch>
            </template>
          </el-table-column>
<!--          <el-table-column prop="enabled" label="应用到所有组织">-->
<!--            <template slot-scope="scope">-->
<!--              <el-switch :disabled="!elec" v-if="scope.row.billingChannel === 'QD-LQ'" v-model="scope.row.applyType" active-text="是" inactive-text="否" :change="switchChange(scope.row)"> </el-switch>-->
<!--            </template>-->
<!--          </el-table-column>-->
        </el-table>
        <!--          <el-row>-->
        <!--            <div style="float: left;padding: 15px 5px;font-size: 14px;">-->
        <!--              应用到所有组织：-->
        <!--              <el-switch :disabled="!isLQ"-->
        <!--                         v-model="applyType"-->
        <!--                         active-text="是"-->
        <!--                         inactive-text="否">-->
        <!--              </el-switch>-->
        <!--            </div>-->

        <!--          </el-row>-->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveOrgPassInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import PHeaderVue from '@/components/pdt/PHeader.vue';
import PSelectVue from '@/components/pdt/PSelect.vue';
import { changeMethods, dateOption, pagingMethods, tableStyle } from '@/util/mixins';
import OrganizationSelect from '@/components/widgets/OrganizationSelect.vue';
import { getOrgPaasInfoTree, selectOrganizationTaxpayer } from '@/service/organization';
import { salConfigPage, salConfig, editPaasInfo } from '@/service/sal-config';
import { getInfosByOrgId, saveOrgPassInfo } from '@/service/paas/paas-service';

export default {
  components: { OrganizationSelect, PSelectVue, PHeaderVue },
  mixins: [tableStyle, pagingMethods, dateOption, changeMethods],
  data() {
    return {
      isTop: false,
      applyType: false,
      isLQ: false,
      elec: '',
      orgId: '',
      orgName: '--',
      tableData: [],
      chanelData: [
        {
          id: 0,
          billingChannel: 'QD-XSD',
          billingChannelName: '机器人X版',
          enabled: false,
          appId: '',
          appSecret: '',
          applyType: false,
          elec: false
        },
        {
          id: 0,
          billingChannel: 'QD-LQ',
          billingChannelName: '全电-乐企',
          enabled: false,
          appId: '',
          appSecret: '',
          applyType: false,
          elec: false
        }
      ],
      dialogVisible: false,
      recordsLoading: false,
      records: [],
      paging: {
        current: 1,
        size: 15,
        total: 0,
        searchCount: true,
        pages: 1
      },
      selections: [],
      pageSizes: [15, 30, 50, 100],
      isIndeterminate: true,
      colOptions: [], //列选项
      checkAll: false, //全选
      billingTypes: [
        {
          billingChannel: '',
          billingChannelName: '全部'
        },
        {
          billingChannel: 'QD-RPA',
          billingChannelName: '全电-RPA'
        },
        {
          billingChannel: 'QD-XSD',
          billingChannelName: '机器人X版'
        },
        {
          billingChannel: 'QD-LQ',
          billingChannelName: '乐企-数电接口'
        }
      ],
      elecOption: [
        {
          value: true,
          label: '是'
        },
        {
          value: false,
          label: '否'
        }
      ],
      elecData: [
        {
          value: '',
          label: '全部'
        },
        {
          value: true,
          label: '是'
        },
        {
          value: false,
          label: '否'
        }
      ],
      record: {
        orgId: '',
        orgName: ''
      },
      selectForm: {
        id: '',
        name: '',
        uniqueCode: '',
        isElec: '',
        taxNo: '',
        billingChannel: ''
      }
    };
  },
  created() {
    this.tableData = this.chanelData;
  },
  mounted() {
    this.query();
  },
  methods: {
    elecFormatter(row, col) {
      return row.elec == 1 ? '是' : '否';
    },
    async saveOrgPassInfo() {
      //全电组织必须选择一个开票通道
      let data = this.tableData.filter(item => item.enabled == true);
      if(this.elec) {
        if(data.length != 1){
          this.toast('通道必须且只能启动一个', 'warning');
          return
        }
      }
      let xsd = this.tableData[0];
      let lq = this.tableData[1];

      let param = {};
      //选择的开票通道
      if(data.length > 0){
        let paasInfo = data[0];
        param.billingChannel = paasInfo.billingChannel;
      }
      param.xsdAppId = xsd.appId;
      param.xsdAppSecret = xsd.appSecret;
      param.lqAppId = lq.appId;
      param.lqAppSecret = lq.appSecret;
      param.orgId = this.orgId;
      param.isElec = this.elec;

      const { success } = await editPaasInfo(param);
      if (success) {
        this.toast('操作成功', 'success');
        this.dialogVisible = false;
        this.query();
      }
    },
    elecChange() {
      this.tableData.forEach((item)=>{
         item.elec = !this.elec;
      })
    },
    async renderTable(orgId) {
      let param = {};
      param.orgId = orgId;
      const { success, data } = await salConfig(param);
      // console.log(data);
      if (success) {
        this.elec = data.isElec == null ? false : data.isElec
        this.tableData = [];
        let xsd = {};
        xsd.id = data.id
        xsd.billingChannelName = '机器人X版'
        xsd.billingChannel = 'QD-XSD'
        xsd.appId = data.xsdAppId
        xsd.appSecret = data.xsdAppSecret
        xsd.elec = !this.elec


        let lq = {};
        lq.id = data.id
        lq.billingChannelName = '乐企'
        lq.billingChannel = 'QD-LQ'
        lq.appId = data.lqAppId
        lq.appSecret = data.lqAppSecret
        lq.elec = !this.elec

        if(data.billingChannel != null){
          switch (data.billingChannel){
            case 'QD-LQ':
              lq.enabled = true;
              break;
            case 'QD-XSD':
              xsd.enabled = true;
              break;
            default:
              break;
          }
        }
        this.tableData = [xsd,lq];
      }
    },
    billChange(row) {
      this.isTop = false;
      this.elec = false;
      this.orgName = '--';
      this.orgId = '';
      this.elec = row.elec;
      this.orgName = row.name;
      this.orgId = row.id;
      this.isTop = row.parentId === 0;
      this.renderTable(row.id);
      this.dialogVisible = true;
    },
    //列设置全选
    handleCheckAllChange(val) {
      this.colOptions = val ? this.colSelect : [];
      this.isIndeterminate = false;
    },
    //查询
    async query() {
      let param = {};
      if (this.selectForm.taxNo) {
        param.taxNo = this.selectForm.taxNo;
      }
      if (this.selectForm.uniqueCode) {
        param.code = this.selectForm.uniqueCode;
      }
      if (this.record.orgId) {
        param.orgId = this.record.orgId;
      }
      if (this.selectForm.isElec !== '') {
        param.isElec = this.selectForm.isElec;
      }
      // if (this.selectForm.billingChannel) {
      //   param.billingChannel = this.selectForm.billingChannel;
      // }
      param.page = this.paging.current;
      param.size = this.paging.size;
      this.recordsLoading = true;
      this.paging.searchCount = true;
      const { success, data } = await getOrgPaasInfoTree(param);
      if (success) {
        // console.log(data);
        this.records = data.map((item) => {
          // item.hasChildren = item.children && item.children.length > 0;
          return item;
        });
        console.log(this.records);
        this.paging.total = data.length;
      }
      this.recordsLoading = false;
    },
    //表单重置
    reset() {
      this.record.orgId = '';
      this.record.orgName = '';
      this.selectForm.name = '';
      this.selectForm.id = '';
      this.selectForm.uniqueCode = '';
      this.selectForm.isElec = '';
      this.selectForm.billingChannel = '';
    },
    // 补全助手名称
    async generateAgentUsername() {
      let orgId = this.record.orgId;
      if (!orgId) {
        this.orgTaxpayer = {};
        this.record.agentUsername = '';
        return;
      }
      const { success, data } = await selectOrganizationTaxpayer(this.record.orgId);
      if (success) {
        this.orgTaxpayer = data;
        this.record.agentUsername = `${this.orgTaxpayer.taxNo}-${this.record.extensionNo}`;
      } else {
        this.orgTaxpayer = {};
        this.record.agentUsername = '';
      }
    },
    handleSelection(value) {
      this.selections = value;
      // if (this.selections.length === 0) {
      // } else {
      // }
    },
    // 切换分页条数
    handleSizeChange(val) {
      this.paging.size = val;
      this.query();
    },

    handleCurrentChange(val) {
      this.paging.current = val;
      this.query();
    }
  }
};
</script>
<style scoped lang="scss">
.invoice-dialog {
  div {
    margin-bottom: 12px;
  }

  span {
    width: 240px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 20px;
  }

  .el-image {
    width: 200px;
    height: 200px;
  }
}

::v-deep .p-content {
  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .top-left {
      display: flex;
      justify-content: flex-start;

      span {
        line-height: 32px;
        font-size: 16px;
        color: #666666;
      }

      .rowSet {
        display: flex;
        align-items: center;
        cursor: pointer;

        .imgSet {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          margin-left: 5px;
        }

        span {
          color: #1890ff;
        }
      }
    }
  }
}

.content-main {
  min-height: calc(100% - 170px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::v-deep .el-button {
    padding: 0;
  }
}

.append-btn {
  width: 135px;
}

.operate-button {
  display: flex;
  justify-content: center;

  .el-button {
    padding: 0;
    margin-right: 10px;
  }
}

.p-header {
  background-color: #fff;
}

.p-select {
  padding: 24px;

  ::v-deep .el-form-item__label {
    text-align: left;
  }
}

.p-content {
  padding-top: 0;

  ::v-deep .el-checkbox__label {
    display: none;
  }

  .table-btn {
    padding: 0 !important;
  }
}

.view {
  position: absolute;
  top: 10px;
  left: 88px;
}
</style>
